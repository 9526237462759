/* 토스트팝업 설정 */
.toast {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32.5px;
  text-align: center;
  letter-spacing: -0.054px;
  color: #ffffff;
}

.Toastify__toast-container {
  padding: 0px !important;
  width: unset !important;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Toastify__toast-theme--light {
  background: rgba(66, 66, 73, 0.5) !important;
  border-radius: 9px !important;
  font-family: "Pretendard" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #ffffff !important;
}

.Toastify__toast-body {
  justify-content: center;
  gap: 5px;
  padding: 6px 30px !important;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: none !important;
}

.Toastify__toast {
  min-width: 335px;
  width: auto;
  padding: 0 !important;
  min-height: 45px !important;
  height: auto;
  /* margin-bottom: 0px !important; */
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast-icon {
  margin-inline-end: 0 !important;
  width: 6px !important;
  height: 6px;
}

/* ::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
}

::-webkit-scrollbar-track {
  background-color: #d9d9d9;
  border-radius: 10px;
  box-shadow: inset 0px 0px 5px white;
} */

/* 텍스트 드래그 css */
::-moz-selection {
  background: #00b3ce;
  color: #fff;
}
::selection {
  background: #00b3ce;
  color: #fff;
}

/* 애니메이션 */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 1s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  /* Firefox */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  /* Safari and Chrome */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  /* Opera */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  animation: fadeOut 1s;
  opacity: 0;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@-moz-keyframes fadeInUp {
  /* Firefox */
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@-webkit-keyframes fadeInUp {
  /* Safari and Chrome */
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@-o-keyframes fadeInUp {
  /* Opera */
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.fadeInUp {
  animation: fadeInUp 1s;
}
